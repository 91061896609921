import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import api from 'services';
import ADRENAAPI from 'services/apiConstant';

// create slice
// Slice

const slice = createSlice({
  name: 'authSlice',
  initialState: {
    loginStatus: null,
    loginUser: null,
    loginUserStatus: null,
    otpVerifyStatus: null,
    phoneOtpVerifyStatus: null,
    addressListData: [],
    adressName: null,
    forgotVerifyStatus: null,
    socialData: null,
    adressData: null,
    launchEmailStatus: null
  },
  reducers: {
    UserLoginSuccess: (state, action) => {
      state.loginUserStatus = action.payload.status;
      state.loginUser = action.payload;
    },
    otpVerifySuccess: (state, action) => {
      state.otpVerifyStatus = action.payload.status;
    },
    phoneOtpVerifySuccess: (state, action) => {
      state.phoneOtpVerifyStatus = action.payload.status;
    },
    ClearLoginStatusSuccess: (state, action) => {
      state.loginStatus = action.payload.data;
    },
    addressSearchSuccess: (state, action) => {
      state.addressListData = action.payload;
    },
    clearAddressSearchSuccess: (state, action) => {
      state.addressListData = action.payload.data;
    },

    addressPlacedSuccess: (state, action) => {
      state.adressData = action.payload;
    },
    forgotVerifyStatus: (state, action) => {
      state.forgotVerifyStatus = action.payload;
    },
    socialLoginSuccess: (state, action) => {
      state.socialData = action.payload;
    },
    clearLoginUserStatusSuccess: (state, action) => {
      state.loginUserStatus = action.payload;
    },
    registerEmailFromCounterScreenSuccess: (state, action) => {
      state.launchEmailStatus = action.payload.status;
    },
    clearSocialLoginState: (state) => {
      state.socialData = null;
      state.loginUserStatus = null;
    }
  }
});
const {
  UserLoginSuccess,
  otpVerifySuccess,
  ClearLoginStatusSuccess,
  addressSearchSuccess,
  clearAddressSearchSuccess,
  addressPlacedSuccess,
  forgotVerifyStatus,
  socialLoginSuccess,
  phoneOtpVerifySuccess,
  clearLoginUserStatusSuccess,
  registerEmailFromCounterScreenSuccess,
  clearSocialLoginState
} = slice.actions;

export const clearSocialLoginData = () => (dispatch) => {
  dispatch(clearSocialLoginState());
};

export const userLogin = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.userLogin}`, data)
    .then((response) => {
      if (response) {
        dispatch(UserLoginSuccess(response.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const userLogout = (data, setAnchorEl) => async () => {
  api
    .post(`${ADRENAAPI.userLogout}`, data)
    .then((response) => {
      if (response.data.status) {
        toast.success(response.data.message);
        secureLocalStorage.clear();
        if (setAnchorEl) {
          setAnchorEl(null);
        }
        window.location.reload();
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const getVerifyOtp = (data) => async () => {
  api
    .post(`${ADRENAAPI.getVerifyOtp}`, data)
    .then((response) => {
      console.log(response);
      if (response.data.status === 1) {
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const getPhoneVerifyOtp = (data, setDialogId) => async () => {
  api
    .post(`${ADRENAAPI.getVerifyOtp}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        toast.success(response.data.message);
        setDialogId(1);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const updateEmailVerifyOtp = (data, setDialogId) => async () => {
  api
    .post(`${ADRENAAPI.updateEmailVerifyOtp}`, data)
    .then((response) => {
      if (response?.data?.status) {
        toast.success(response.data.message);
        if (setDialogId) {
          setDialogId(1);
        }
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const verifyOtp =
  (data, setShowDialog, registerPhone, setDialogData) => async (dispatch) => {
    api
      .post(`${ADRENAAPI.verifyOtp}`, data)
      .then((response) => {
        if (response.data.status === 1) {
          if (setShowDialog) {
            setShowDialog(false);
          }
          if (setDialogData) {
            setDialogData((dialogData) => ({
              ...dialogData,
              isPhoneVerified: true
            }));
          }
          if (!registerPhone) {
            secureLocalStorage.setItem('authenticated', JSON.stringify(response.data));
          }
          toast.success(response.data.message);
        }
        dispatch(otpVerifySuccess(response.data));
      })
      .catch(() => {});
  };
export const verifyPhoneOtp = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.verifyPhoneOtp}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        secureLocalStorage.setItem('authenticated', JSON.stringify(response.data));
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
      dispatch(phoneOtpVerifySuccess(response.data));
    })
    .catch(() => {});
};
export const updateVerifyeOtp = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.updateVerifyeOtp}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        secureLocalStorage.setItem('authenticated', JSON.stringify(response.data));
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
      dispatch(phoneOtpVerifySuccess(response.data));
    })
    .catch(() => {});
};

export const ClearLoginStatus = (data) => (dispatch) => {
  dispatch(ClearLoginStatusSuccess(data));
};

export const addressSearch = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.addressSearch}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      if (response.data.status) {
        dispatch(addressSearchSuccess(response.data.data.predictions));
      }
    })
    .catch(() => {});
};
export const clearAddressSearch = (data) => (dispatch) => {
  dispatch(clearAddressSearchSuccess(data));
};

export const addressPlaced = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.addressPlaced}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      if (response.data.status) {
        dispatch(addressPlacedSuccess(response.data.data.result));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};

export const forgotPassword = (data) => async () => {
  api
    .post(`${ADRENAAPI.forgotPassword}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const forgotVerifyOtp = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.verifyOtp}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        dispatch(forgotVerifyStatus(true));
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const changePasswordWithOtp = (data, setAuthId) => async () => {
  api
    .post(`${ADRENAAPI.changePasswordWithOtp}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        toast.success(response.data.message);
        setAuthId(3);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};

export const getSocialAccountInfo = (data, setLoading) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getSocialAccountInfo}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(socialLoginSuccess(response.data));
      } else {
        setLoading(false);
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const registerEmailFromCounterScreen = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.registerEmailFromCounterScreen}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(registerEmailFromCounterScreenSuccess(response.data));
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const clearLoginUserStatus = () => async (dispatch) => {
  dispatch(clearLoginUserStatusSuccess(null));
};

//  new auth flow

// auth Check Email Exist
export const authCheckEmailExist = (data, setAuthId) => async () => {
  api
    .post(`${ADRENAAPI.authCheckEmailExist}`, data)
    .then((response) => {
      if (response?.data?.status) {
        if (response?.data?.data) {
          setAuthId(2);
        } else {
          setAuthId(1);
        }
        // toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};

// user login
export const authUserLogin =
  (data, formData, setLoading, navigate, handleSendOtp, setAuthId, setloginData, state) =>
  async () => {
    api
      .post(`${ADRENAAPI.authUserLogin}`, data)
      .then((response) => {
        if (response?.data?.status) {
          if (response?.data?.data?.passwordChangeRequired) {
            setLoading(false);
            setAuthId(6);
            setloginData(response?.data);
          } else {
            if (formData?.email) {
              if (response?.data?.data?.isEmailVerified === false) {
                handleSendOtp();
              } else {
                toast.success(response?.data?.message);
                secureLocalStorage.setItem('authenticated', JSON.stringify(response?.data));
                if (state) {
                  navigate(state);
                } else {
                  navigate('/');
                }
                // window.location.reload();
              }
            } else {
              if (response?.data?.data?.isPhoneVerified === false) {
                handleSendOtp();
              } else {
                toast.success(response?.data?.message);
                secureLocalStorage.setItem('authenticated', JSON.stringify(response?.data));
                if (state) {
                  navigate(state);
                } else {
                  navigate('/');
                }
                // window.location.reload();
              }
            }
          }
        } else {
          setLoading(false);
          toast.warning(response.data.message);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

// forgot password

export const authForgotPassword = (data, type, setAuthId, setIsloading) => async () => {
  api
    .post(`${ADRENAAPI.authForgotPassword}`, data)
    .then((response) => {
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        if (type == 'phone') {
          setAuthId(1);
        } else {
          setAuthId(1);
        }
      } else {
        toast.warning(response.data.message);
      }
      setIsloading(false);
    })
    .catch(() => {});
};

//  Verify OTP
export const authVerifyOTP = (data, setAuthId, navigate, setLoading, state) => async () => {
  api
    .post(`${ADRENAAPI.authVerifyOTP}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        if (data?.verificationType == 'forgotPassword') {
          toast.success(response.data.message);
          setAuthId(2);
        } else {
          toast.success(response?.data?.message);
          secureLocalStorage.setItem('authenticated', JSON.stringify(response?.data));
          if (state) {
            navigate(state);
          } else {
            navigate('/');
          }
          // window.location.reload();
        }
      } else {
        toast.warning(response.data.message);
        if (setLoading) {
          setLoading(false);
        }
      }
    })
    .catch(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

// change password with otp
export const authChangePasswordWithOtp =
  (data, setAuthId, setIsloading, handleChangePasswordSuccess) => async () => {
    api
      .post(`${ADRENAAPI.authChangePasswordWithOtp}`, data)
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          if (setAuthId) {
            setAuthId(3);
          }
          if (handleChangePasswordSuccess) {
            handleChangePasswordSuccess();
          }
        } else {
          toast.warning(response.data.message);
        }
        if (setIsloading) {
          setIsloading(false);
        }
      })
      .catch(() => {});
  };
// Get Verification Otp for email and phone verification
export const authGetVerificationOtp = (data, nextPage, setLoading) => async () => {
  api
    .post(`${ADRENAAPI.authGetVerificationOtp}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        toast.success(response.data.message);
        if (nextPage) {
          nextPage();
        }
      } else {
        toast.warning(response.data.message);
      }
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch(() => {});
};

// user registration
// user login
export const authUserRegister = (data, setLoading, handleSendOtp) => async () => {
  api
    .post(`${ADRENAAPI.authUserRegister}`, data)
    .then((response) => {
      if (response?.data?.status) {
        handleSendOtp();
      } else {
        setLoading(false);
        toast.warning(response.data.message);
      }
    })
    .catch(() => {
      setLoading(false);
    });
};

export default slice.reducer;
